.careerPageFocusContainerStyle {
  opacity: 1;
  transition: 0.15s ease-in-out;
}
.careerPageBlurContainerStyle {
  opacity: 0;
  transition: 0.15s ease-in-out;
}

.careerPageInsideContainerStyle {
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 128px 64px 100px 64px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.careerPageHeaderContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.careerPageHeaderTopContainerStyle {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.careerPageHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--black);
}
.careerPageHeaderOrangeTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 56px;
  line-height: 60px;
  color: var(--orange-dark);
}
.careerPageHeaderContentTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.careerPageHeaderViewMoreContentTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-opacity-80);
}
.readMoreTextStyle {
  display: none;
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
  text-decoration: underline;
  cursor: pointer;
}
.readLessTextStyle {
  display: none;
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
  text-decoration: underline;
  cursor: pointer;
}
.careerPageHeaderItemsWrapperStyle {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 64px;
}
.careerPageHeaderItemContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 32px;
  height: fit-content;
}
.careerPageHeaderItemLineStyle {
  border-left: 1px solid var(--black-opacity-20);
  height: auto;
  width: 1px;
}
.contactTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
  text-decoration: underline;
  cursor: pointer;
  word-break: keep-all;
  white-space: nowrap;
}
.careerOpenPositionsContainerStyle {
  width: 720px;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.careerOpenPositionsHeaderWrapperStyle {
  border-bottom: 1px solid var(--black-opacity-20);
  padding-bottom: 32px;
}
.careerOpenPositionsHeaderTextStyle {
  font-family: "NeueHassDisplay-SemiBold", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: var(--black);
}
.careerOpenPositionItemContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid var(--black-opacity-20);
  padding-bottom: 16px;
  text-decoration: none;
}
.careerJobPositionWrapperStyle {
  display: flex;
  flex-direction: column;
}
.careerJobItemTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}
.careerJobApplyDateExpiryTextStyle {
  font-family: "Manrope-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-opacity-50);
}
.imgWrapperStyle {
  width: 24px;
  height: 24px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.careerOurStoryContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
}
.careerOurStoryTextStyle {
  font-family: "Manrope-Medium", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: var(--orange-dark);
}
@media screen and (max-width: 1440px) {
  .careerPageHeaderContentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .contactTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .careerOurStoryTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .readMoreTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .readLessTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .careerPageHeaderViewMoreContentTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .careerPageHeaderItemsWrapperStyle {
    gap: 32px;
  }
}
@media screen and (max-width: 1080px) {
  .careerPageInsideContainerStyle {
    padding: 104px 74px 80px 74px;
    gap: 80px;
  }
  .careerPageHeaderContainerStyle {
    gap: 48px;
  }
  .careerPageHeaderTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .careerPageHeaderOrangeTextStyle {
    font-size: 48px;
    line-height: 52px;
  }
  .careerPageHeaderItemsWrapperStyle {
    grid-template-columns: auto;
    gap: 32px;
    border-top: 1px solid var(--black-opacity-20);
    padding-top: 32px;
  }
  .careerPageHeaderItemLineStyle {
    display: none;
  }
  .readMoreTextStyle {
    display: block;
  }
  .readLessTextStyle {
    display: block;
  }
  .careerPageHeaderViewMoreContentTextStyle {
    display: none;
  }
}
@media screen and (max-width: 867px) {
  .careerPageInsideContainerStyle {
    padding: 104px 32px 64px 32px;
    gap: 64px;
  }
  .careerPageHeaderItemsWrapperStyle {
    gap: 24px;
    padding-top: 24px;
  }
  .careerOpenPositionsHeaderTextStyle {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 485px) {
  .careerPageInsideContainerStyle {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .careerPageHeaderTopContainerStyle {
    gap: 24px;
  }
  .careerPageHeaderTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .careerPageHeaderOrangeTextStyle {
    font-size: 40px;
    line-height: 44px;
  }
  .careerOpenPositionsHeaderWrapperStyle {
    padding-bottom: 24px;
  }
  .careerJobItemTextStyle {
    font-size: 18px;
    line-height: 28px;
  }
}
